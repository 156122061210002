.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 3 / 4 !important;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__portal {
    margin-bottom: 50px;

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  button {
    position: absolute;
    bottom: var(--ra-spacing-6);
    left: 0;
    right: 0;
    margin-inline: 48px;
  }
}
