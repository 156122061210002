.ra-heading {
  $root: &;

  @at-root {
    h1#{$root},
    #{$root}#{$root}--size-h1 {
      --ra-heading-size: var(--ra-font-size-heading-1);
    }
    h2#{$root},
    #{$root}#{$root}--size-h2 {
      --ra-heading-size: var(--ra-font-size-heading-2);
    }
    h3#{$root},
    #{$root}#{$root}--size-h3 {
      --ra-heading-size: var(--ra-font-size-heading-3);
    }
    h4#{$root},
    #{$root}#{$root}--size-h4 {
      --ra-heading-size: var(--ra-font-size-heading-4);
    }
    h5#{$root},
    #{$root}#{$root}--size-h5 {
      --ra-heading-size: var(--ra-font-size-heading-5);
    }
  }

  font-family: var(--ra-font-family-heading);
  font-weight: var(--ra-font-weight-regular);
  font-size: var(--ra-heading-size);
  text-wrap: balance;

  &--color-primary {
    color: var(--color-heading);
  }

  &--color-light {
    color: var(--color-neutral-1);
  }

  &--color-dark {
    color: var(--color-body);
  }

  &--shadow {
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.26);
  }

  &--no-padding {
    margin: 0;
    padding: 0;
  }

  &--align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }
}
