@import '@styles/mixins.style';

.card {
  width: 280px;
  height: 100%;

  a {
    height: 100%;
  }

  @include breakpoint(md) {
    width: 384px;
  }

  @include breakpoint(xl) {
    width: 488px;
  }

  &__image {
    width: 100%;
    height: 188px;
    @include breakpoint(md) {
      height: 225px;
    }

    picture,
    img {
      height: 225px;
      width: 100%;
      object-fit: cover;
      border-radius: var(--ra-border-radius-xl) var(--ra-border-radius-xl) 0px
        0px;
    }
  }

  &__content {
    position: relative;
    padding: var(--ra-spacing-4);
    height: auto;
    &__badge {
      position: absolute;
      top: -36px;
      left: 24px;
      background-color: var(--color-neutral-2);
      padding: var(--ra-spacing-2);
      border-radius: 99px;
    }

    h4 {
      margin-top: var(--ra-spacing-2);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
  }
}
