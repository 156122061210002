@import '@styles/mixins.style';

.card {
  --image-width: 260px;
  --image-height: 200px;

  padding: var(--ra-spacing-1);

  &__content {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 100% !important;

    @include breakpoint(sm) {
      display: grid;
      grid-template-columns: 240px 1fr;
      gap: var(--ra-spacing-6);
    }

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: 240px 6fr 5fr;
      gap: var(--ra-spacing-6);
    }

    @include breakpoint(xl) {
      grid-template-columns: 240px 6fr 5fr;
    }

    &--bb {
      @include breakpoint(md) {
        grid-template-columns: 4fr 6fr 325px;
      }

      > :first-child {
        @include breakpoint(md) {
          margin-right: 12px;
        }
        @include breakpoint(xl) {
          margin-right: 24px;
        }
      }
    }
  }

  &__description {
    padding: var(--ra-spacing-4);
  }

  &__header {
    position: relative;
    display: grid;
    grid-template-columns: 36px 1fr;
    gap: var(--ra-spacing-3);
    margin-bottom: var(--ra-spacing-5);
    align-items: center;

    h3 {
      word-break: break-word;
    }

    &--rt {
      display: flex;
      align-items: center;

      &:first-child {
        justify-content: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        border: 1px dotted var(--color-translucent-dark-2);
      }
    }

    &--bb {
      position: relative;
      display: flex;
      align-items: center;

      span {
        position: absolute;
        top: 9px;
        left: 11px;
      }
    }

    &--dd-index {
      span {
        left: 6px;
      }
    }
  }

  &__details {
    &--mobile {
      margin-block: 32px;
      @include breakpoint(md) {
        display: none;
      }
      @include breakpoint-max(sm) {
        margin-block: var(--ra-spacing-4);
      }
    }

    &--desktop {
      display: none;
      @include breakpoint(md) {
        display: inline;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: var(--ra-spacing-3);
    justify-content: center;

    @include breakpoint(md) {
      justify-content: flex-start;
    }
  }

  &__images {
    &--mobile {
      max-width: 100%;
      overflow: hidden;
      min-height: 200px;
      margin-inline: var(--ra-spacing-2);

      @include breakpoint(sm) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @include breakpoint(sm) {
        display: inline;
        position: relative;

        &__images {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 90%;
          z-index: 10;
          transition: transform 200ms ease;
          padding: 24px;

          &--bb {
            transform: translateX(24px) scale(1.2);
          }

          :nth-child(even) {
            transform: translateX(40px) rotate(7deg);
          }
          &__erlebe {
            &[data-type='rt'] {
              :nth-child(odd) {
                transform: none;
              }
              :nth-child(even) {
                transform: translateX(0) rotate(0);
                margin-block: var(--ra-spacing-3);
              }
            }
          }

          > img {
            transition: transform 200ms ease-in-out;
          }
        }
      }
    }
  }

  &__stepmap {
    display: none;
    @include breakpoint(md) {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 200ms ease;
      height: var(--stepmap-width-md);
      width: var(--stepmap-width-md);

      * {
        width: var(--stepmap-width-md);
      }

      img {
        object-fit: contain;
      }

      @include breakpoint(xl) {
        width: var(--stepmap-width-xl);

        * {
          width: var(--stepmap-width-xl);
        }
      }
    }
  }

  &:hover {
    .card-product__images--desktop {
      &__images {
        > img:nth-child(odd) {
          transform: rotate(6deg) scale(0.95);
        }

        > img:nth-child(even) {
          transform: translateX(40px) rotate(-2deg) scale(1.05);
        }
      }
    }

    .card-product__stepmap {
      transform: rotate(1.5deg);
    }
  }

  &__wishlist {
    position: absolute;
    top: var(--ra-spacing-5);
    right: var(--ra-spacing-5);
    background-color: white;
    z-index: 2;
    border-radius: 50%;
    padding: 4px;

    @include breakpoint(sm) {
      background-color: transparent;
      padding: 0;
    }
  }
}
