@import '@styles/mixins.style';

.ra-container {
  display: grid;
  max-width: 100%;
  margin-inline: 16px;
  height: 100%;

  @include breakpoint(sm) {
    margin-inline: 40px;
  }

  @include breakpoint(lg) {
    max-width: 1280px;
    margin-inline: auto;
  }

  @include breakpoint(xl) {
    max-width: 1512px;
  }

  &--max-width {
    @include breakpoint(lg) {
      max-width: var(--max-container-width);
    }
  }
}
