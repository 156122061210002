.ra-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ra-spacing-2);
  text-transform: capitalize;

  li {
    display: flex;
    align-items: center;
    a {
      margin-right: var(--ra-spacing-2);
    }
  }
}
