@import '@styles/mixins.style';

.alignright {
  margin-bottom: 24px;
  display: block;
  width: 100%;
  object-fit: cover;
  @include breakpoint(sm) {
    display: initial;
    width: fit-content;
    float: right;
    margin-left: 24px;
    margin-bottom: 0;
  }
}

.alignleft {
  margin-bottom: 24px;
  display: block;
  width: 100%;
  object-fit: cover;
  @include breakpoint(sm) {
    display: initial;
    width: fit-content;
    float: left;
    margin-right: 24px;
    margin-bottom: 0;
  }
}
