.ra-back-to-top {
  position: fixed;
  right: var(--ra-spacing-4);
  bottom: var(--ra-spacing-4);
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 66px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(24px);
  transition: all 0.35s ease;
  z-index: 999;

  > div {
    transition: box-shadow 350ms ease;
  }

  &:hover {
    > div {
      box-shadow: var(--ra-elevation-heavy);
    }
  }

  &--active {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);

    &:hover {
      cursor: pointer;
    }
    &:focus-visible {
      border: var(--ra-focus-outline);
    }
  }

  &--offset-bottom {
    bottom: 100px;
  }
}
