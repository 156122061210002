.ra-rich-text {
  max-width: 100%;
  &--heading {
    &-primary {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--color-heading);
      }
    }

    &-light {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--color-light);
      }
    }

    &-dark {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--color-dark);
      }
    }
  }

  a {
    font-weight: var(--ra-font-weight-semibold);
  }

  &--decorate-lists {
    &--bullets {
      ul {
        padding-left: var(--ra-spacing-5);
        list-style-type: disc;
      }
    }
    &--checkmarks {
      li {
        position: relative;
        padding-left: var(--ra-spacing-6);
        padding-bottom: var(--ra-spacing-5);
        &::before {
          position: absolute;
          content: '';
          background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="3" width="18" height="18" rx="9" fill="transparent"/><rect x="3.375" y="3.375" width="17.25" height="17.25" rx="8.625" stroke="white" stroke-opacity="0.24" stroke-width="0.75" stroke-dasharray="0.75 1.5"/><path d="M16.9576 9.51159L10.5615 15.9077L7.00378 12.35L7.71148 11.6423L10.5615 14.4923L16.2499 8.80389L16.9576 9.51159Z" fill="%23FFFDF9"/></svg>');
          background-color: var(--color-primary);
          left: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 1px dotted var(--color-white);
        }

        &:last-child {
          padding-bottom: 0;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  &--decorate-tables {
    > div {
      max-width: 90vw !important;
    }
    table {
      border: none;
      border-collapse: collapse;
      white-space: nowrap;

      th,
      tr,
      td {
        border: none;
        text-align: left;
        font-size: var(--ra-font-size-body-s) !important;
      }

      tr {
        border-bottom: 1px solid hsla(0, 0%, 50.2%, 0.7);
        padding: 5px 5px 5px 0;
        vertical-align: top;
        white-space: nowrap;
      }

      th,
      td {
        padding-right: 8px;
      }
    }
  }

  &--small {
    font-size: var(--ra-font-size-body-xs);
  }

  img {
    max-width: 100%;
  }

  iframe {
    background: none !important;
  }
}
