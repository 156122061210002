@import '@styles/mixins.style';

.card {
  max-width: 100%;
  padding-inline: var(--ra-spacing-4);
  padding-block: var(--ra-spacing-6);
  gap: var(--ra-spacing-5);
  display: flex;
  flex-direction: column;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  border-color: var(--color-translucent-dark-1);

  @include breakpoint(md) {
    padding-block: var(--ra-spacing-5);
    padding-inline: var(--ra-spacing-7);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--ra-spacing-6);
  }
}
